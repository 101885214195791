import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";
const ResetPasswordRedirect = () => {
  let history = useHistory();
  let params = useParams();

  useEffect(() => {
    if (params.key) {
      secureLocalStorage.setItem("reset-key", params.key);
      history.push("/reset-password");
    } else {
      history.push("/login");
    }
  }, []);

  return (
    <>
    </>
  );
};

export default ResetPasswordRedirect;
