import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import secureLocalStorage from "react-secure-storage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOADING_MESSAGE, SET_MESSAGE } from "../../actions/types";

import {
  getPlans,
  getCurrentPlans,
  stripeCheckout,
  cancelSubscription,
} from "../../services/user.service";

import { Modal, Switch } from "antd";
import { TextField } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { logout } from "../../actions/auth";
const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },

  cardPricing: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
    "& .MuiTypography-root": {
      color: "#ffffff",
    },
  },
  cstCard: {
    minWidth: 310,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    border: "none",
    borderRadius: "2px",
    backgroundColor: "#a140b1",
    color: "#ffffff",
    "& .MuiCardActionArea-root": {
      paddingBlock: "40px",
      padding: "7px",
    },
    "&:hover": {
      backgroundColor: "#ac69b7",
      borderRadius: "8px",
      color: "#ffffff",
    },
  },
  cstCardDisabled: {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    border: "none",

    "& .MuiCardActionArea-root": {
      paddingBlock: "40px",
      padding: "7px",
    },

    backgroundColor: "#ac69b7",
    borderRadius: "8px",
    color: "#ffffff",
  },

  container: {
    // backgroundColor: "#a140b1",
  },
}));
const CssTextField = withStyles({
  root: {
    "& label": {
      color: "Black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "& input": {
        background: "white",
        borderRadius: 5,
      },
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        // borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        // borderColor: "green",
      },
    },
  },
})(TextField);
const tiers = [
  {
    title: "Personal",
    price: "0",
    description:
      "Personal subscription for hosting and sharing pitures and point cloud",
    buttonText: "Select",
    buttonVariant: "contained",
  },
  {
    title: "Commercial",

    price: "15",
    description: [
      "20 users included",
      "10 GB of storage",
      "Help center access",
      "Priority email support",
    ],
    buttonText: "Select",
    buttonVariant: "contained",
  },
  {
    title: "Enterprise",
    price: "30",
    description: [
      "50 users included",
      "30 GB of storage",
      "Help center access",
      "Phone & email support",
    ],
    buttonText: "Select",
    buttonVariant: "contained",
  },
];
const UpgradePlans = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.message);
  const [planType, setPlanType] = useState("monthly");
  const [subscriptionSelected, setSubscriptionSelected] = useState(null);
  const [storageSelected, setStorageSelected] = useState(null);
  const [userSelected, setUserSelected] = useState(1);
  const [storageQuantity, setStorageQuantity] = useState(1);
  const [plans, setPlans] = useState([]);
  const [totalCost, setTotalCost] = useState({ data: null, value: 0 });
  const [steps, setSteps] = useState(1);
  const [mySub, setMySub] = useState(null);

  const changeInputHandler = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  useEffect(() => {
    let total = 0;
    let priceArray = [];
    plans.forEach((item) => {
      // if (item.type == "Subscription") {
      if (item.name == subscriptionSelected) {
        let temp = item.stripe_prices.filter((itemPrice) =>
          planType.includes(itemPrice.recurring?.interval)
        ).length
          ? plans
              .filter((item) => item.name == subscriptionSelected)[0]
              .stripe_prices.filter((itemPrice) =>
                planType.includes(itemPrice.recurring?.interval)
              )[0]
          : plans.filter((item) => item.name == subscriptionSelected)[0]
              .stripe_prices[0];
        total = total + (Number(temp.unit_amount) / 100) * Number(userSelected);
        priceArray.push({
          price: temp.price_id,
          quantity: Number(userSelected),
        });
      }
      // } else if (item.type == "Storage") {
      //   if (
      //     subscriptionSelected == "Commercial" ||
      //     [4, 5, 6, 7].includes(
      //       JSON.parse(secureLocalStorage.getItem("typeID"))
      //     )
      //     //   &&
      //     // item.quantity
      //   ) {
      if (item.name == storageSelected) {
        let temp = item.stripe_prices.filter((itemPrice) =>
          planType.includes(itemPrice.recurring?.interval)
        ).length
          ? plans
              .filter((item) => item.name == storageSelected)[0]
              .stripe_prices.filter((itemPrice) =>
                planType.includes(itemPrice.recurring?.interval)
              )[0]
          : plans.filter((item) => item.name == storageSelected)[0]
              .stripe_prices[0];
        total = total + (Number(temp.unit_amount) / 100) * storageQuantity;
        priceArray.push({
          price: temp.price_id,
          quantity: storageQuantity,
        });
      }
      // total = total + item[planType].cost * item.quantity;
      // priceArray.push({
      //   price: item[planType].id,
      //   quantity: item.quantity,
      // });
      //   }
      // } else if (item.type == "Users") {
      //   if (
      //     subscriptionSelected == "Commercial" ||
      //     [5, 6, 7].includes(JSON.parse(secureLocalStorage.getItem("typeID")))
      //     //   &&
      //     // item.quantity
      //   ) {
      //     if (item.name == userSelected) {
      //       total = total + item[planType].cost;
      //       priceArray.push({
      //         price: item[planType].id,
      //         quantity: 1,
      //       });
      //     }
      //     // total = total + item[planType].cost * item.quantity;
      //     // priceArray.push({
      //     //   price: item[planType].id,
      //     //   quantity: item.quantity,
      //     // });
      //   }
      // }
    });
    setTotalCost({ data: priceArray, value: total });
  }, [
    planType,
    subscriptionSelected,
    plans,
    userSelected,
    storageSelected,
    storageQuantity,
  ]);

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let flag = urlParams.get("payment");
    if (flag) {
      history.push("/login?payment=true");
      dispatch(logout());
      history.push("/login?payment=true");
      return;
    }
    if (
      secureLocalStorage.getItem("isOrganizationAdmin") ||
      !JSON.parse(secureLocalStorage.getItem("organizationID"))
    ) {
      dispatch({ type: LOADING_MESSAGE, isLoading: true });
      getPlans()
        .then((data) => {
          if (data.status === 200) {
            const tiers = data?.data?.data
              .sort(function(a, b) {
                return (
                  Number(
                    a.stripe_prices.filter((itemPrice) =>
                      "monthly".includes(itemPrice.recurring?.interval)
                    )[0]?.unit_amount
                  ) -
                  Number(
                    b.stripe_prices.filter((itemPrice) =>
                      "monthly".includes(itemPrice.recurring?.interval)
                    )[0]?.unit_amount
                  )
                );
              })
              .map((item) => {
                item.quantity = 0;
                return item;
              });
            setPlans(tiers);
            getCurrentPlans()
              .then((res) => {
                let keys = Object.keys(res.data.productCounts);
                let mySubs = tiers.filter((item) => keys.includes(item.name));
                setTimeout(() => {
                  let temp = mySubs.filter(
                    (item) =>
                      tiers.filter((itemFil) => itemFil.id == item.id)[0]
                        .type == "service"
                  )[0];
                  let temp1 = mySubs.filter(
                    (item) =>
                      tiers.filter((itemFil) => itemFil.id == item.id)[0]
                        .type == "storage"
                  )[0];
                  let temp2 = res.data.productDetails.filter(
                    (item) => item.name == temp?.name
                  )[0];
                  if (temp) {
                    setSubscriptionSelected(temp?.name);
                    setUserSelected(res.data.productCounts[temp?.name]);
                    setPlanType(
                      temp2?.price.interval == "month" ? "monthly" : "yearly"
                    );
                  }
                  if (temp1) {
                    setStorageSelected(temp1?.name);
                    setStorageQuantity(res.data.productCounts[temp1?.name]);
                  }

                  setMySub(keys.length ? temp2 : null);
                }, 500);
              })
              .catch((error) => {
                setMySub(null);
                // const message =
                //   error?.response?.data?.message ||
                //   error?.message ||
                //   error?.toString();
                // dispatch({
                //   type: SET_MESSAGE,
                //   payload: { message: message, severity: "error", isOpen: true },
                // });
              });
          }
          dispatch({ type: LOADING_MESSAGE, isLoading: false });
        })
        .catch((error) => {
          const message =
            error?.response?.data?.message ||
            error?.message ||
            error?.toString();
          dispatch({
            type: SET_MESSAGE,
            payload: { message: message, severity: "error", isOpen: true },
          });
        });
    }
    sessionStorage.setItem("reloadMap", 1);
  }, []);

  return (
    <>
      <div className="MainPlans"></div>
      <h1 style={{ color: "#a140b1", fontSize: 50, textAlign: "center" }}>
        Manage Subscription{" "}
        {/* <Switch
          checked={planType == "yearly"}
          style={{
            zoom: 3,
          }}
          checkedChildren={
            <div
              style={{
                zoom: 0.3,
              }}
            >
              <span style={{ fontWeight: "700" }}>Pay Upfront</span> <br />
              <span
                style={{
                  fontWeight: "700",
                  color: "rgb(0, 189, 165)",
                }}
              >
                Best Value
              </span>{" "}
              <br />
              <span>Commit annually</span>
            </div>
          }
          unCheckedChildren={
            <div
              style={{
                zoom: 0.3,
              }}
            >
              <span style={{ fontWeight: "700" }}>Pay Monthly</span> <br />
              <span>More expensive</span>
            </div>
          }
          onChange={(checked) => setPlanType(checked ? "yearly" : "monthly")}
        />{" "} */}
      </h1>
      {secureLocalStorage.getItem("isOrganizationAdmin") ? null : !JSON.parse(
          secureLocalStorage.getItem("organizationID")
        ) ? null : (
        <h1
          style={{
            textAlign: "center",
            background: "red",
            color: "white",
          }}
        >
          Please contact your organization admin to make changes to your
          account.
        </h1>
      )}
      {/* <div
        className="SigninWrapp"
        style={{ overflow: "auto", height: "calc(100vh - 155px)" }}
      >
        <div className="SigninTitle">
          <div className="inlineBox">
            <h2>Update Info</h2>
          </div>
          <div className="inlineBox text-right">
            <img src={SigninImage} alt="" />
          </div>
        </div> */}
      <Container maxWidth="lg" component="main" className={classes.container}>
        {/* <div style={{ textAlign: "center" }}>
          <TextField
            onChange={(e) => setUserSelected(e.target.value)}
            id="outlined-number"
            inputProps={{ min: 1 }}
            label="Users"
            disabled={!subscriptionSelected}
            type="number"
            value={userSelected}
            style={{
              zoom: "1",
              marginLeft: 60,
              textAlignLast: "center",
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <span className="upgradeCounterText">
            {" "}
            X $
            {subscriptionSelected
              ? plans
                  .filter((item) => item.name == subscriptionSelected)[0]
                  .stripe_prices.filter((itemPrice) =>
                    planType.includes(itemPrice.recurring?.interval)
                  ).length
                ? plans
                    .filter((item) => item.name == subscriptionSelected)[0]
                    .stripe_prices.filter((itemPrice) =>
                      planType.includes(itemPrice.recurring?.interval)
                    )[0]?.unit_amount / 100
                : plans.filter((item) => item.name == subscriptionSelected)[0]
                    .stripe_prices[0]?.unit_amount / 100
              : 0}{" "}
          </span>
        </div> */}
        <Grid container alignItems="center" justify="center">
          {subscriptionSelected ? (
            <Grid item xs={12} md={3} alignItems="center" justify="left">
              <h2 style={{ color: "#a140b1" }}>Current Subscription</h2>
              <Grid container justify="left">
                {plans?.map(
                  (tier) =>
                    tier.type.includes("service") &&
                    subscriptionSelected == tier.name && (
                      // Enterprise card is full width at sm breakpoint
                      <Grid item key={tier.name}>
                        <Card
                          className={classes.cstCard}
                          //   onClick={() => {
                          //     setSubscriptionSelected(
                          //       tier.name == subscriptionSelected ? null : tier.name
                          //     );
                          //   }}
                        >
                          <CardActionArea>
                            <CardHeader
                              title={tier.name}
                              subheader={tier.subheader}
                              titleTypographyProps={{ align: "left" }}
                              subheaderTypographyProps={{ align: "left" }}
                              className={classes.cardHeader}
                              style={{ padding: "0px 0px 0px 15px" }}
                            />
                            <CardContent>
                              <ul>
                                <Typography
                                  component="li"
                                  variant="p"
                                  align="left"
                                  style={{ paddingBottom: "25px" }}
                                >
                                  {tier.description}
                                </Typography>
                              </ul>
                              <div className={classes.cardPricing}>
                                <Typography
                                  component="h2"
                                  variant="h3"
                                  color="textPrimary"
                                >
                                  {/* ${tier[planType].cost} */}$
                                  {tier.stripe_prices.filter((itemPrice) =>
                                    planType.includes(
                                      itemPrice.recurring?.interval
                                    )
                                  ).length
                                    ? planType == "monthly"
                                      ? (
                                          (tier.stripe_prices.filter(
                                            (itemPrice) =>
                                              planType.includes(
                                                itemPrice.recurring?.interval
                                              )
                                          )[0]?.unit_amount /
                                            100) *
                                          userSelected
                                        ).toFixed(2)
                                      : (
                                          (
                                            tier.stripe_prices.filter(
                                              (itemPrice) =>
                                                planType.includes(
                                                  itemPrice.recurring?.interval
                                                )
                                            )[0]?.unit_amount /
                                            100 /
                                            12
                                          ).toFixed(2) * userSelected
                                        ).toFixed(2)
                                    : planType == "monthly"
                                    ? (
                                        (tier.stripe_prices[0]?.unit_amount /
                                          100) *
                                        userSelected
                                      ).toFixed(2)
                                    : (
                                        (
                                          tier.stripe_prices[0]?.unit_amount /
                                          100 /
                                          12
                                        ).toFixed(2) * userSelected
                                      ).toFixed(2)}
                                </Typography>
                                <Typography variant="h6" color="textSecondary">
                                  {planType == "monthly" ? "/month" : "/month"}
                                </Typography>
                              </div>
                              <ul>
                                <li
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 10,
                                  }}
                                >
                                  <div>
                                    <Typography
                                      style={{
                                        textAlign: "left",
                                        color: "white",
                                      }}
                                      variant="h6"
                                      color="textSecondary"
                                    >
                                      Users:{" "}
                                      <CssTextField
                                        disabled={true}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        onChange={(e) =>
                                          setUserSelected(e.target.value)
                                        }
                                        style={{ width: 100, marginBottom: 10 }}
                                        id="outlined-number"
                                        inputProps={{ min: 1 }}
                                        // label="Users"
                                        // disabled={!subscriptionSelected}
                                        type="number"
                                        size="small"
                                        value={userSelected}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        variant="outlined"
                                      />
                                      {!JSON.parse(
                                        secureLocalStorage.getItem(
                                          "organizationID"
                                        )
                                      ) ? (
                                        <span className="noTeamSub">
                                          Create Team for extra users
                                        </span>
                                      ) : null}
                                    </Typography>
                                  </div>
                                  <Switch
                                    disabled
                                    checked={planType == "yearly"}
                                    style={{
                                      zoom: 3,
                                    }}
                                    checkedChildren={
                                      <div
                                        style={{
                                          zoom: 0.3,
                                        }}
                                      >
                                        <span style={{ fontWeight: "700" }}>
                                          Pay Upfront
                                        </span>{" "}
                                        <br />
                                        <span
                                          style={{
                                            fontWeight: "700",
                                            color: "#AAFF00",
                                          }}
                                        >
                                          Best Value
                                        </span>{" "}
                                        <br />
                                        <span>Commit annually</span>
                                      </div>
                                    }
                                    unCheckedChildren={
                                      <div
                                        style={{
                                          zoom: 0.3,
                                        }}
                                      >
                                        <span style={{ fontWeight: "700" }}>
                                          Pay Monthly
                                        </span>{" "}
                                        <br />
                                        <span style={{ color: "yellow" }}>
                                          More expensive
                                        </span>
                                      </div>
                                    }
                                    onClick={(a, b, c) => {
                                      b.stopPropagation();
                                    }}
                                    onChange={(checked) =>
                                      setPlanType(
                                        checked ? "yearly" : "monthly"
                                      )
                                    }
                                  />
                                </li>
                              </ul>

                              <div
                                style={{ paddingLeft: 16 }}
                                dangerouslySetInnerHTML={{
                                  __html: tier.product_info,
                                }}
                              ></div>
                              {/* <li>{1} User</li>
                              <li>
                                {parseInt(
                                  tier.metadata.storage.replace(",", "")
                                ) / 1024}{" "}
                                GB Storage
                              </li> */}
                            </CardContent>
                            {/* <CardActions>
                          <Button
                            fullWidth
                            variant={"contained"}
                            style={{
                              background:
                                subscriptionSelected == tier.name
                                  ? "green"
                                  : "",
                            }}
                            color={"primary"}
                          >
                            {subscriptionSelected == tier.name
                              ? "Selected"
                              : "Select"}
                          </Button>
                        </CardActions> */}
                          </CardActionArea>
                        </Card>
                      </Grid>
                    )
                )}
              </Grid>
            </Grid>
          ) : null}

          {storageSelected ? (
            <Grid
              style={{ marginLeft: 30 }}
              item
              xs={12}
              md={3}
              alignItems="center"
              justify="center"
            >
              <h2 style={{ color: "#a140b1" }}>Current Storage Add-on</h2>
              <Grid container alignItems="center" justify="left">
                {plans?.map(
                  (tier) =>
                    tier.type.includes("storage") &&
                    storageSelected == tier.name && (
                      // Enterprise card is full width at sm breakpoint
                      <Grid item key={tier.name}>
                        <Card
                          //   onClick={() => {
                          //     // if (
                          //     //   subscriptionSelected !== "Commercial" &&
                          //     //   ![4, 5, 6, 7].includes(
                          //     //     JSON.parse(secureLocalStorage.getItem("typeID"))
                          //     //   )
                          //     // )
                          //     // return;
                          //     setStorageSelected(
                          //       tier.name == storageSelected ? null : tier.name
                          //     );
                          //   }}
                          className={
                            // subscriptionSelected !== "Commercial" &&
                            // ![4, 5, 6, 7].includes(
                            //   JSON.parse(secureLocalStorage.getItem("typeID"))
                            // )
                            //   ? classes.cstCardDisabled
                            classes.cstCard
                          }
                        >
                          <CardActionArea>
                            <CardHeader
                              title={tier.name}
                              subheader={tier.subheader}
                              titleTypographyProps={{ align: "left" }}
                              subheaderTypographyProps={{ align: "left" }}
                              action={tier.name === "Pro" ? <StarIcon /> : null}
                              className={classes.cardHeader}
                              style={{ padding: "0px 0px 0px 15px" }}
                            />
                            <CardContent>
                              <ul>
                                <Typography
                                  component="li"
                                  variant="p"
                                  align="left"
                                  style={{ paddingBottom: "25px" }}
                                >
                                  {tier.description}
                                </Typography>
                              </ul>
                              <div className={classes.cardPricing}>
                                <Typography
                                  component="h2"
                                  variant="h3"
                                  color="textPrimary"
                                >
                                  $
                                  {(
                                    (tier.stripe_prices.filter((itemPrice) =>
                                      planType.includes(
                                        itemPrice.recurring?.interval
                                      )
                                    ).length
                                      ? planType == "monthly"
                                        ? tier.stripe_prices.filter(
                                            (itemPrice) =>
                                              planType.includes(
                                                itemPrice.recurring?.interval
                                              )
                                          )[0]?.unit_amount / 100
                                        : (
                                            tier.stripe_prices.filter(
                                              (itemPrice) =>
                                                planType.includes(
                                                  itemPrice.recurring?.interval
                                                )
                                            )[0]?.unit_amount /
                                            100 /
                                            12
                                          ).toFixed(2)
                                      : planType == "monthly"
                                      ? tier.stripe_prices[0]?.unit_amount / 100
                                      : (
                                          tier.stripe_prices[0]?.unit_amount /
                                          100 /
                                          12
                                        ).toFixed(2)) *
                                    (tier.quantity ? tier.quantity : 1)
                                  ).toFixed(2)}
                                </Typography>
                                <Typography variant="h6" color="textSecondary">
                                  {planType == "monthly" ? "/month" : "/month"}
                                </Typography>
                              </div>
                              <ul>
                                <li
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 10,
                                  }}
                                >
                                  <div>
                                    <Typography
                                      style={{
                                        textAlign: "left",
                                        color: "white",
                                      }}
                                      variant="h6"
                                      color="textSecondary"
                                    >
                                      Quantity:{" "}
                                      <CssTextField
                                        disabled
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        onChange={(e) =>
                                          setStorageQuantity(e.target.value)
                                        }
                                        style={{ width: 100, marginBottom: 10 }}
                                        id="outlined-number"
                                        inputProps={{ min: 1 }}
                                        // label="Users"
                                        // disabled={!subscriptionSelected}
                                        type="number"
                                        size="small"
                                        value={storageQuantity}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        variant="outlined"
                                      />
                                    </Typography>
                                  </div>
                                  <Switch
                                    disabled
                                    checked={planType == "yearly"}
                                    style={{
                                      zoom: 3,
                                    }}
                                    onClick={(a, b, c) => {
                                      b.stopPropagation();
                                    }}
                                    checkedChildren={
                                      <div
                                        style={{
                                          zoom: 0.3,
                                        }}
                                      >
                                        <span style={{ fontWeight: "700" }}>
                                          Pay Upfront
                                        </span>{" "}
                                        <br />
                                        <span
                                          style={{
                                            fontWeight: "700",
                                            color: "#AAFF00",
                                          }}
                                        >
                                          Best Value
                                        </span>{" "}
                                        <br />
                                        <span>Commit annually</span>
                                      </div>
                                    }
                                    unCheckedChildren={
                                      <div
                                        style={{
                                          zoom: 0.3,
                                        }}
                                      >
                                        <span style={{ fontWeight: "700" }}>
                                          Pay Monthly
                                        </span>{" "}
                                        <br />
                                        <span style={{ color: "yellow" }}>
                                          More expensive
                                        </span>
                                      </div>
                                    }
                                    onChange={(checked) =>
                                      setPlanType(
                                        checked ? "yearly" : "monthly"
                                      )
                                    }
                                  />
                                </li>
                              </ul>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    )
                )}
              </Grid>
            </Grid>
          ) : null}
          {!subscriptionSelected && !storageSelected ? (
            secureLocalStorage.getItem("isOrganizationAdmin") ||
            !JSON.parse(secureLocalStorage.getItem("organizationID")) ? (
              <h1 style={{ color: "#a140b1", marginBlock: "140px" }}>
                No Subscriptions
              </h1>
            ) : (
              <h1 style={{ color: "#a140b1", marginBlock: "140px" }}></h1>
            )
          ) : null}
        </Grid>
        {mySub ? (
          <h1 className="expiryTextManage">
            Expires on {mySub.currentPeriodEnd.split("T")[0]}
          </h1>
        ) : null}
        {/* {steps == 1 && (
          <>
            <h1 style={{ color: "#a140b1" }}>Users</h1>
            <Grid
              container
              style={{
                minHeight: "345px",
              }}
              alignItems="center"
              justify="center"
            >
              
            </Grid>
          </>
        )} */}
        <span className="upgradeButtonManage">
          {mySub ? (
            <Button
              disabled={mySub?.isCancellationRequested}
              onClick={() => {
                Modal.confirm({
                  title: "Warning!",
                  icon: <ExclamationCircleOutlined />,
                  okText: "Proceed",
                  okType: "primary",
                  cancelText: "Cancel",
                  content: (
                    <>
                      <h3>
                        Do you really want to cancel your current subscriptions?
                      </h3>
                    </>
                  ),
                  onOk: () => {
                    dispatch({ type: LOADING_MESSAGE, isLoading: true });
                    cancelSubscription()
                      .then((res) => {
                        dispatch({ type: LOADING_MESSAGE, isLoading: false });
                        getCurrentPlans().then((res) => {
                          let keys = Object.keys(res.data.productCounts);
                          setMySub(
                            keys.length
                              ? res.data.productDetails.filter(
                                  (item) => item.name == mySub.name
                                )[0]
                              : null
                          );
                        });
                      })
                      .catch((error) => {
                        const message =
                          error?.response?.data ||
                          error?.response?.data?.message ||
                          error?.message ||
                          error?.toString();
                        dispatch({
                          type: SET_MESSAGE,
                          payload: {
                            message: message,
                            severity: "error",
                            isOpen: true,
                          },
                        });
                        dispatch({ type: LOADING_MESSAGE, isLoading: false });
                      });
                  },
                  onCancel: () => {},
                });
              }}
              variant="contained"
              color="secondary"
            >
              {mySub?.isCancellationRequested
                ? "Cancelled"
                : "Cancel Subscription"}
            </Button>
          ) : null}

          <Button
            variant={"contained"}
            style={{
              // background:
              //   subscriptionSelected == tier.name ? "green" : "",
              marginLeft: 10,
            }}
            onClick={() => {
              history.push("/upgrade-plan");
            }}
            color={"primary"}
          >
            Upgrade Subscription
          </Button>
        </span>
      </Container>
      {/* {steps == 3 && (
        <>
          <h1 style={{ color: "#a140b1", fontSize: 40, textAlign: "center" }}>
            Total Cost: ${totalCost.value.toFixed(2)}
          </h1>
        </>
      )} */}
      <br />
      <br />
      <br />
      <br />
      {/* </div> */}
      <Backdrop open={isLoading} style={{ zIndex: 99999999999, color: "#fff" }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}

export default UpgradePlans;
