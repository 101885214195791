import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { register, signupGoogle } from "../../actions/auth";
import { LOADING_MESSAGE } from "../../actions/types";
import { industries } from "../common/common.js";
import SigninImage from "../images/avatar.png";
import ReCAPTCHA from "react-google-recaptcha";
import { SET_MESSAGE } from "./../../actions/types";
import ValidatorMessage from "./subcomponents/Recaptcha";
let repPass = "";
let repCap = null;
const MyLink = (props) => <Link to="/login" {...props} />;

const Signin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [recaptcha, setrecaptcha] = useState(null);
  const [formData, setFormData] = useState(
    {
      phone: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      repeatPassword: "",
      country: "",
      state: "",
      city: "",
      industry: "",
      company: "",
      terms: false,
      recaptcha: "",
      optIn: false,
    },
    shallowEqual
  );
  const { message, isLoading } = useSelector(
    (state) => state.message,
    shallowEqual
  );

  const changeInputHandler = (e) => {
    const { name, value, checked, type } = e.target;
    if (name === "password") {
      repPass = value;
    }
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };
  const onChangeRecaptcha = (a) => {
    repCap = a;
    setrecaptcha(a);
  };
  useEffect(() => {
    repPass = "";
    repCap = null;
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== repPass) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("isTruthy", (value) => value);
    ValidatorForm.addValidationRule("recaptchaValidator", () => {
      if (repCap === null) {
        return false;
      }
      return true;
    });
  }, []);

  useEffect(() => {
    if (message?.severity == "success") {
      setTimeout(() => history.push("/login"), 3000);
    }
  }, [message]);

  const handleRegister = (e) => {
    e.preventDefault();
    if (!formData.country || !formData.state) {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: formData.country ? "Select state" : "Select country",
          severity: "error",
          isOpen: true,
        },
      });
      return;
    }
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    dispatch(register(formData));
  };

  return (
    <>
      <div className="MainLoginBg"></div>
      <div
        className="SigninWrapp"
        style={{ overflow: "auto", height: "calc(100vh - 155px)" }}
      >
        <div className="SigninTitle">
          <div className="inlineBox">
            <h2>Sign up for the Beta</h2>
          </div>
          <div className="inlineBox text-right">
            <img src={SigninImage} alt="" />
          </div>
        </div>
        {/* //Uncommit it when social signup work done
        <Tabs value={value} onChange={handleTabs}>
          <Tab label="SIGN UP WITH EMAIL" />
          <Tab label="SIGN UP WITH SOCIAL MEDIA" />
        </Tabs> */}
        <div className="form-group" style={{ height: 50 }}>
          <GoogleLogin
            text="signup_with"
            theme="filled_blue"
            onSuccess={(res) => {
              dispatch({ type: LOADING_MESSAGE, isLoading: true });
              dispatch(signupGoogle({ access_token: res.credential }));
            }}
            onError={(err) => {
              console.log(err);
            }}
          />
        </div>
        <div className="inlineBox">
          <h2 style={{ textAlign: "center", fontSize: "18px" }}>Or signup</h2>
        </div>
        <TabPanel value={0} index={0}>
          <ValidatorForm className="FormStyle" onSubmit={handleRegister}>
            <div>
              <label>Email</label>
              <TextValidator
                validators={["required", "isEmail"]}
                errorMessages={[
                  "This field is required.",
                  "email is not valid",
                ]}
                name="email"
                autoComplete="new-password"
                value={formData.email}
                onChange={changeInputHandler}
                type="text"
                className="form-control"
                placeholder="Email"
              />
            </div>
            <div>
              <label>Password</label>
              <TextValidator
                placeholder="Password"
                fullWidth
                onChange={changeInputHandler}
                autoComplete="new-password"
                name="password"
                type="password"
                validators={[
                  "required",
                  "matchRegexp:^(?=.*[0-9])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,}$",
                ]}
                errorMessages={[
                  "This field is required.",
                  "Password should be minimum eight characters, at least one letter and one number",
                ]}
                value={formData.password}
              />
            </div>
            <div>
              <label>Confirm Password</label>
              <TextValidator
                placeholder="Confirm Password"
                fullWidth
                onChange={changeInputHandler}
                name="repeatPassword"
                type="password"
                validators={["isPasswordMatch", "required"]}
                errorMessages={["password mismatch", "This field is required."]}
                value={formData.repeatPassword}
              />
            </div>
            <div>
              <label>First name</label>
              <TextValidator
                validators={["required"]}
                errorMessages={["This field is required."]}
                name="firstName"
                value={formData.firstName}
                onChange={changeInputHandler}
                type="text"
                className="form-control"
                placeholder="First name"
              />
            </div>
            <div>
              <label>Last name</label>
              <TextValidator
                validators={["required"]}
                errorMessages={["This field is required."]}
                name="lastName"
                value={formData.lastName}
                onChange={changeInputHandler}
                type="text"
                className="form-control"
                placeholder="Last name"
              />
            </div>
            <div>
              <label>Phone number</label>
              <TextValidator
                validators={["required"]}
                errorMessages={["This field is required."]}
                name="phone"
                value={formData.phone}
                onChange={changeInputHandler}
                type="text"
                className="form-control"
                placeholder="Phone number"
              />
            </div>
            <div>
              <label>Industry</label>
              <TextValidator
                id="standard-select-currency"
                select
                onChange={changeInputHandler}
                helperText="Please select your Industry"
                variant="standard"
                validators={["required"]}
                errorMessages={["This field is required."]}
                name="industry"
                value={formData.industry}
                className="form-control"
              >
                {industries.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextValidator>
            </div>
            <div>
              <label>Country</label>
              <CountryDropdown
                style={{ fontFamily: "revert" }}
                value={formData.country}
                className="form-control"
                defaultOptionLabel="Select Country"
                onChange={(value) =>
                  setFormData({ ...formData, country: value })
                }
              />
            </div>
            <div>
              <label>State</label>
              <RegionDropdown
                style={{ fontFamily: "revert" }}
                country={formData.country}
                value={formData.state}
                className="form-control"
                defaultOptionLabel="Select State"
                onChange={(value) => setFormData({ ...formData, state: value })}
              />
            </div>
            <div>
              <label>City</label>
              <TextValidator
                // validators={["required"]}
                // errorMessages={["This field is required."]}
                name="city"
                value={formData.city}
                onChange={changeInputHandler}
                type="text"
                className="form-control"
                placeholder="City"
              />
            </div>
            <div>
              <label>Company</label>
              <TextValidator
                validators={["required"]}
                errorMessages={["This field is required."]}
                name="company"
                value={formData.company}
                onChange={changeInputHandler}
                className="form-control"
                placeholder="Company name"
              />
            </div>
            <div>
              <ValidatorMessage
                name="privacy"
                value={formData.terms}
                validators={["isTruthy"]}
                errorMessages={["This field is required."]}
                className="error-message"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="terms"
                      checked={formData.terms}
                      onChange={changeInputHandler}
                    />
                  }
                  label={
                    <>
                      I certify that I am over the age of 18, and agree to the{" "}
                      <Link target={"_blank"} to="/terms-of-service">
                        Terms of Service
                      </Link>{" "}
                      and{" "}
                      <a
                        target={"_blank"}
                        href="https://www.metageo.io/privacy-policy"
                      >
                        Privacy Policy
                      </a>
                    </>
                  }
                />
              </ValidatorMessage>
            </div>
            <div>
              <ValidatorMessage
                name="optIn"
                value={formData.optIn}
                className="error-message"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="optIn"
                      checked={formData.optIn}
                      onChange={changeInputHandler}
                    />
                  }
                  label={
                    <>
                      I agree to receive other communications from METAGEO Inc..
                    </>
                  }
                />
              </ValidatorMessage>
            </div>
            <div style={{ marginBottom: 15 }}>
              <ValidatorMessage
                name="recaptcha"
                value={recaptcha}
                validators={["recaptchaValidator"]}
                errorMessages={["this field is required"]}
                className="error-message"
              >
                <FormControlLabel
                  style={{ marginLeft: 0 }}
                  value={recaptcha}
                  control={
                    <ReCAPTCHA
                      sitekey="6Ldws2odAAAAAFfLQHc_LzfkGjcTN4FTr6Cz3RGv"
                      onChange={onChangeRecaptcha}
                    />
                  }
                />
              </ValidatorMessage>
            </div>
            <div>
              <Button
                type="submit"
                variant="contained"
                className="btn-block btnTheme"
                disabled={isLoading}
              >
                Sign Up
              </Button>
            </div>
          </ValidatorForm>
        </TabPanel>
        <div className="text-center terms dont">
          <p>
            Have an account?
            <Button component={MyLink} to="/login">
              {" "}
              Log In
            </Button>
          </p>
        </div>
      </div>
      <div className="Overlay"></div>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}

export default Signin;
