import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import BlockIcon from "@material-ui/icons/Block";
import Check from "@material-ui/icons/Check";
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import RefreshIcon from "@material-ui/icons/Refresh";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import InfoIcon from "@material-ui/icons/Info";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createAdminOrg,
  createUser,
  deleteOrganization,
  deleteUser,
  getAllOrganization,
  getAllOrgs,
  resendVerification,
  restoreOrganization,
  updateAdminOrg,
  updateUser,
} from "../../services/user.service";
import { displayMessage } from "./../common/common";
import Slide from "@material-ui/core/Slide";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Input,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { Avatar, List } from "antd";
import { ORGANIZATIONS } from "../../actions/types";
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <BlockIcon {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function validateField(val) {
  return val != "";
}

const OrganizationTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [backDropOpen, setBackDropOpen] = useState(true);
  const [userModal, setUserModal] = useState(false);
  const [users, setUsers] = useState([]);
  const getAllOrgsFunc = () => {
    getAllOrgs().then((res) => {
      let types = {};
      res.data.organizations.forEach((singletype) => {
        types[singletype.organizationID] = singletype.organizationName;
      });
      types[null] = "No Organization";
      dispatch({
        type: ORGANIZATIONS,
        payload: types,
      });
    });
  };
  const columns = [
    { title: "ID", field: "organizationID", editable: "never" },
    {
      title: "Name",
      field: "organizationName",

      validate: (rowData) => {
        if (validateField(rowData.organizationName)) {
        }

        return validateField(rowData.organizationName) &&
          rowData.organizationName
          ? ""
          : "Name is required";
      },
    },
    {
      title: "Description",
      field: "organizationDescription",

      validate: (rowData) => {
        if (validateField(rowData.organizationDescription)) {
        }

        return validateField(rowData.organizationDescription) &&
          rowData.organizationDescription
          ? ""
          : "Description is required";
      },
    },
    {
      title: "Storage",
      field: "storageCapacity",
      editComponent: (props) => (
        <Input
          type="number"
          // disabled={
          //   props.rowData.organizationID &&
          //   props.rowData.organizationID !== "null" &&
          //   !props.rowData.isOrganizationAdmin
          // }
          inputProps={{ min: 0 }}
          defaultValue={props.value / 1024}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      type: "numeric",
      render: (col) => {
        return (col.storageCapacity / 1024).toFixed(2) + " GB";
      },
      editable: "onUpdate",
    },
    { title: "No. of Users", field: "users.length", editable: "never" },
    { title: "Users Limit", field: "limit", editable: "onUpdate" },
    {
      title: "Date Created",
      field: "createdAt",
      editable: "never",
      render: (rowData) => {
        return (
          rowData.createdAt.split("T")[0] +
          " " +
          rowData.createdAt.split("T")[1].substring(0, 8)
        );
      },
    },
    {
      title: "Status",
      field: "active",
      editable: "never",
      render: (rowData) => {
        return rowData.active ? "Active" : "Inactive";
      },
    },
    {
      title: "Admin Email",
      field: "email",
      validate: (rowData) => {
        if (validateEmail(rowData.email)) {
        }
        return validateEmail(rowData.email) ? "" : "Email is invalid";
      },
      // render: (rowData) => {
      //   let filtered = rowData.users.filter((item) => item.isOrganizationAdmin);
      //   return filtered.length ? (
      //     <Link to={`/user-profile?user=${filtered[0].email}`}>
      //       {filtered[0].email}
      //     </Link>
      //   ) : (
      //     ""
      //   );
      // },
    },
  ];

  useEffect(() => {
    getAllOrganization().then((res) => {
      let organizations = res.data.organizations.map((org) => {
        if (org.owner) {
          org.email = org.owner.email;
        } else {
          org.email = "";
        }
        return org;
      });
      setData(organizations);
      setBackDropOpen(false);
    });
  }, []);

  return (
    <div className="margindiv usersTable">
      <MaterialTable
        localization={{
          body: {
            editRow: {
              deleteText:
                "Are you sure you want to initiate the deletion of this organization?",
            },
            deleteTooltip: "Deactivate",
          },
        }}
        actions={[
          {
            icon: InfoIcon,
            // disabled:rowData.isVerified?true:false,
            tooltip: "Users list",
            onClick: (event, rowData) => {
              setUserModal(true);
              setUsers(rowData.users);
              //   if (!rowData.isVerified) {
              //     new Promise((resolve, reject) => {
              //       setBackDropOpen(true);
              //       resendVerification(rowData.email).then((res) => {
              //         resolve();
              //         dispatch(
              //           displayMessage("success", "Invite resent successfully")
              //         );
              //         setBackDropOpen(false);
              //       });
              //     });
              //   } else {
              //     dispatch(displayMessage("error", "User is already verified!"));
              //   }
            },
          },
          {
            icon: RefreshIcon,
            tooltip: "Restore Organization",
            onClick: (event, rowData) => {
              if (!rowData.active) {
                new Promise((resolve, reject) => {
                  setBackDropOpen(true);
                  restoreOrganization(rowData.organizationID).then((res) => {
                    const dataRestore = [...data];
                    const index = rowData.tableData.id;
                    dataRestore[index].active = true;
                    resolve();
                    dispatch(
                      displayMessage(
                        "success",
                        "Organization restored successfully"
                      )
                    );
                    setBackDropOpen(false);
                  });
                });
              } else {
                dispatch(
                  displayMessage("error", "Organization is already Active")
                );
              }
            },
          },
        ]}
        options={{
          exportButton: true,
          exportAllData: true,
        }}
        icons={tableIcons}
        title="All Organizations"
        columns={columns}
        data={data}
        editable={{
          onRowAdd: (newData) => {
            return new Promise((resolve, reject) => {
              if (
                newData.organizationName != "" &&
                newData.organizationDescription != "" &&
                validateEmail(newData.email)
              ) {
                createAdminOrg(newData)
                  .then((res) => {
                    getAllOrgsFunc();
                    newData = res.data.organization;
                    newData.users = [res.data.orgAdmin];
                    newData.owner = res.data.orgAdmin;
                    newData.email = res.data.orgAdmin?.email
                      ? res.data.orgAdmin?.email
                      : "";
                    setData([...data, newData]);
                    resolve();
                    dispatch(
                      displayMessage(
                        "success",
                        "Organization added successfully"
                      )
                    );
                  })
                  .catch((error) => {
                    const message =
                      error?.response?.data?.message ||
                      error?.message ||
                      "Organization not created";
                    dispatch(displayMessage("error", message));
                    reject();
                  });
              } else {
                reject();
              }
            });
          },
          onRowUpdate: (newData, oldData) => {
            return new Promise((resolve, reject) => {
              if (
                newData.organizationName != "" &&
                newData.organizationDescription != ""
              ) {
                updateAdminOrg(
                  oldData.email == newData.email
                    ? {
                        organizationName: newData.organizationName,
                        organizationDescription:
                          newData.organizationDescription,
                        limit: newData.limit,
                        storageCapacity:
                          oldData.storageCapacity == newData.storageCapacity
                            ? oldData.storageCapacity
                            : newData.storageCapacity * 1024,
                      }
                    : {
                        organizationName: newData.organizationName,
                        organizationDescription:
                          newData.organizationDescription,
                        limit: newData.limit,
                        email: newData.email,
                        storageCapacity:
                          oldData.storageCapacity == newData.storageCapacity
                            ? oldData.storageCapacity
                            : newData.storageCapacity * 1024,
                      },
                  newData.organizationID
                )
                  .then((res) => {
                    getAllOrgsFunc();
                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    newData.storageCapacity =
                      oldData.storageCapacity == newData.storageCapacity
                        ? oldData.storageCapacity
                        : newData.storageCapacity * 1024;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);

                    resolve();
                    dispatch(
                      displayMessage(
                        "success",
                        "Organization updated successfully"
                      )
                    );
                  })
                  .catch((error) => {
                    const message =
                      error?.response?.data?.message ||
                      error?.message ||
                      "Organization not created";
                    dispatch(displayMessage("error", message));
                    reject();
                  });
              } else {
                reject();
              }
            });
          },
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              if (oldData.active) {
                deleteOrganization(oldData.organizationID).then((res) => {
                  getAllOrgsFunc();
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  dataDelete[index].active = false;
                  // setData([...dataDelete]);
                  resolve();
                  dispatch(
                    displayMessage("success", "Organization deletion initiated")
                  );
                });
              } else {
                resolve();
                dispatch(
                  displayMessage(
                    "error",
                    "Organization deletion already in progress"
                  )
                );
              }
            }),
        }}
      />
      <Dialog
        open={userModal}
        TransitionComponent={Transition}
        onClose={() => setUserModal(false)}
        className="projectUsers"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Organization Users"}
        </DialogTitle>
        <List style={{ height: 320, overflow: "auto" }}>
          {users.map((user) => {
            return (
              <ListItem button key={user.email}>
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={user.firstName + " " + user.lastName} />
                <ListItemText
                  style={{ marginLeft: 5 }}
                  primary={"(" + user.email + ")"}
                />
              </ListItem>
            );
          })}
        </List>
        <DialogActions>
          <Button onClick={() => setUserModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        open={backDropOpen}
        style={{ zIndex: 99999999999, color: "#fff" }}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default OrganizationTable;
