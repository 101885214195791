import React from "react";

const Scroll = (props) => {
  return (
    <div style={{ overflowY: "scroll", height: "200px", paddingLeft: "15px" }}>
      {props.children}
    </div>
  );
};

export default Scroll;
