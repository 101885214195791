import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { login, resendotp, signupGoogle, verifyotp } from "../../actions/auth";
import { GoogleLogin } from "@react-oauth/google";
import SigninImage from "../images/avatar.png";
import { LOADING_MESSAGE, SET_MESSAGE } from "../../actions/types";
import secureLocalStorage from "react-secure-storage";
import { resendVerification } from "../../services/user.service";
import { displayMessage } from "../common/common";
import { useHistory } from "react-router-dom";
const MyLink = (props) => <Link to="/signup" {...props} />;

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let flag = urlParams.get("payment");
    if (flag) {
      history.replace({
        search: "",
      });
      dispatch(
        displayMessage("success", "New subscription added. Please relogin.")
      );
    }
  }, []);
  const { isOtp } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [unverified, setUnverified] = useState(false);
  const { message, isLoading } = useSelector(
    (state) => state.message,
    shallowEqual
  );
  useEffect(() => {
    if (message?.message == "Please verify your email.") {
      setUnverified(true);
    }
  }, [message]);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    dispatch(login(email, password));
  };

  const handleResendOtp = (e) => {
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    let otpEmail = secureLocalStorage.getItem("otpEmail");
    let otpPassword = secureLocalStorage.getItem("otpPassword");
    dispatch(resendotp(otpEmail, otpPassword));
  };

  const handleResendVerify = (e) => {
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    let otpEmail = secureLocalStorage.getItem("otpEmail");
    resendVerification(otpEmail)
      .then((res) => {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            message: res.data.message,
            severity: "success",
            isOpen: true,
          },
        });
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message || error?.message || error?.toString();
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, severity: "error", isOpen: true },
        });
      });
  };

  const handleOtp = (e) => {
    e.preventDefault();
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    let otpEmail = secureLocalStorage.getItem("otpEmail");
    dispatch(verifyotp(otpEmail, password));
  };

  return (
    <div className="MainLoginBg">
      <div className="Overlay"></div>
      {isOtp ? (
        <div className="SigninWrapp">
          <div className="SigninTitle">
            <div className="inlineBox inlineBoxOtp">
              <h2>OTP Verification</h2>
            </div>
          </div>
          <ValidatorForm onSubmit={handleOtp} className="FormStyle">
            <div style={{ marginBottom: 15 }}>
              <label>OTP code</label>
              <TextValidator
                TextValidator
                fullWidth
                onChange={onChangePassword}
                name="password"
                type="password"
                validators={["required"]}
                errorMessages={["this field is required"]}
                value={password}
                placeholder="e.g. 12345"
              />
            </div>
            <div className="form-group">
              <Button
                type="submit"
                variant="contained"
                className="btn-block btnTheme"
                disabled={isLoading}
              >
                Verify
              </Button>
            </div>
            <div className="form-group">
              <div className="text-center forgot">
                <Button
                  onClick={() => {
                    handleResendOtp();
                  }}
                >
                  Resend Otp
                </Button>
              </div>
            </div>
            {/* <p className="Suggestion">
              <span>OR SIGN IN USING</span>
            </p> */}
          </ValidatorForm>
          {/* <div className="SocialButtons">
            <div className="row">
              <div className="col-6">
                <Button variant="contained" className="btnSocial">
                  <img src={FacebookLogin} alt="" /> <span>Facebook</span>
                </Button>
              </div>
              <div className="col-6">
                <Button variant="contained" className="btnSocial widthFix">
                  <img src={GoogleLogin} alt="" /> <span>Google</span>
                </Button>
              </div>
            </div>
            <div className="clearFix"></div>
          </div> */}
          <div className="text-center terms">
            <p>
              By continuing, you agree to metageo{" "}
              <Link target={"_blank"} to="/terms-of-service">
                Terms of Service
              </Link>{" "}
              and{" "}
              <a target={"_blank"} href="https://www.metageo.io/privacy-policy">
                Privacy Policy
              </a>
            </p>
          </div>
          <div className="text-center terms dont">
            <p>
              Don't have an account?
              <Button component={MyLink} to="/signup">
                Sign Up
              </Button>
            </p>
          </div>
        </div>
      ) : (
        <div className="SigninWrapp">
          <div className="SigninTitle">
            <div className="inlineBox">
              <h2>Log In</h2>
            </div>
            <div className="inlineBox text-right">
              <img src={SigninImage} alt="" />
            </div>
          </div>
          <ValidatorForm onSubmit={handleLogin} className="FormStyle">
            <div style={{ marginBottom: 15 }}>
              <label>Email</label>
              <TextValidator
                validators={["required", "isEmail"]}
                errorMessages={["this field is required", "email is not valid"]}
                name="email"
                value={email}
                onChange={onChangeEmail}
                type="text"
                className="form-control"
                placeholder="Email e.g.xyz@metageo.com"
              />
            </div>
            <div style={{ marginBottom: 15 }}>
              <label>Password</label>
              <TextValidator
                autoComplete="current-password"
                fullWidth
                onChange={onChangePassword}
                name="password"
                type="password"
                validators={password.length ? null : ["required"]}
                errorMessages={["this field is required"]}
                // value={passwordString(password)}
                placeholder="e.g. 12345"
              />
            </div>

            {unverified && (
              <>
                <div className="form-group">
                  <div className="text-center forgot">
                    <Button
                      onClick={() => {
                        handleResendVerify();
                      }}
                    >
                      Resend Verification link
                    </Button>
                  </div>
                  <h6
                    style={{
                      color: "red",
                      marginBottom: "10px",
                      textAlign: "center",
                    }}
                  >
                    Please check your email for the verification link
                  </h6>
                </div>
              </>
            )}

            <div className="form-group">
              <Button
                type="submit"
                variant="contained"
                className="btn-block btnTheme"
                disabled={isLoading}
              >
                Sign In
              </Button>
            </div>
            <div className="form-group">
              <div className="text-center forgot">
                <Button component={MyLink} to="/forget-password">
                  Forgot Password
                </Button>
              </div>
            </div>
            <div className="form-group" style={{ height: 50 }}>
              <GoogleLogin
                theme="filled_blue"
                onSuccess={(res) => {
                  dispatch({ type: LOADING_MESSAGE, isLoading: true });
                  dispatch(signupGoogle({ access_token: res.credential }));
                }}
                onError={(err) => {
                  console.log(err);
                }}
              />
            </div>
            {/* <p className="Suggestion">
              <span>OR SIGN IN USING</span>
            </p> */}
          </ValidatorForm>
          {/* <div className="SocialButtons">
            <div className="row">
              <div className="col-6">
                <Button variant="contained" className="btnSocial">
                  <img src={FacebookLogin} alt="" /> <span>Facebook</span>
                </Button>
              </div>
              <div className="col-6">
                <Button variant="contained" className="btnSocial widthFix">
                  <img src={GoogleLogin} alt="" /> <span>Google</span>
                </Button>
              </div>
            </div>
            <div className="clearFix"></div>
          </div> */}
          <div className="text-center terms">
            <p>
              By continuing, you agree to metageo{" "}
              <Link target={"_blank"} to="/terms-of-service">
                Terms of Service
              </Link>{" "}
              and{" "}
              <a target={"_blank"} href="https://www.metageo.io/privacy-policy">
                Privacy Policy
              </a>
            </p>
          </div>
          <div className="text-center terms dont">
            <p>
              Don't have an account?
              <Button component={MyLink} to="/signup">
                Sign Up
              </Button>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
