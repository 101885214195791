import React from "react";
import BorderImage from "../images/border.png";
import ProfileImage from "../images/profileImage.png";
import starIcon from "../images/starIcon.png";
import P1 from "../images/p-1.png";
import P2 from "../images/p-2.png";
import P3 from "../images/p-3.png";
import P4 from "../images/p-4.png";
import P5 from "../images/p-5.png";
import P6 from "../images/p-6.png";
import P7 from "../images/p-7.png";
import P8 from "../images/p-8.png";
import P9 from "../images/p-9.png";
import P10 from "../images/p-10.png";
import P11 from "../images/p-11.png";
import P12 from "../images/p-12.png";
import P13 from "../images/p-13.png";
import P14 from "../images/p-14.png";
import P15 from "../images/p-15.png";
import P16 from "../images/p-16.png";
import { Router, Switch, Route, Link, withRouter } from "react-router-dom";
const VideoHome = () => {
  return (
    <div className="MainBG">
      <div className="WelcomeSection">
        <div className="container-xl">
          <div className="BoxOne">
            <h2>Who are we?</h2>
            <img src={BorderImage} />
            <p className="BreakGraph">
              700 million monthly active users touch our maps. This creates a
              feedback cycle, where our AI-powered data pipeline processes +300
              million miles of anonymized and aggregated live road telemetry
              data daily — allowing METAGEO to continuously update the map{" "}
            </p>
            <p>
              700 million monthly active users touch our maps. This creates a
              feedback cycle, where our AI-powered data pipeline processes +300
              million miles of anonymized and aggregated live road telemetry
              data daily — allowing METAGEO to continuously update the map
              everywhere in the world. Businesses need continuously updating
              traffic, roads, places around the world and our platform shares
              this live map with developers via our scalable mapping software
              components (maps, navigation, search) for mobile and web
              applications, logistics and dispatching, and in- car automotive
              navigation.
            </p>
          </div>
          <div className="BoxTwo">
            <div className="ImageSide">
              <img src={ProfileImage} />
            </div>
            <div className="ContentSide">
              <h2>TESTIMONIALS</h2>
              <p>
                700 million monthly active users touch our maps. This creates a
                feedback cycle, where our AI-powered data pipeline processes
                +300 million miles of anonymized and aggregated live road
                telemetry data daily — allowing METAGEO to continuously update
                the map 700 million monthly active users touch our maps. This
                creates a feedback cycle, where our AI-powered data pipeline
                processes +300
              </p>
              <ul>
                <li>
                  <a href="#.">
                    <img src={starIcon} />
                  </a>
                </li>
                <li>
                  <a href="#.">
                    <img src={starIcon} />
                  </a>
                </li>
                <li>
                  <a href="#.">
                    <img src={starIcon} />
                  </a>
                </li>
                <li>
                  <a href="#.">
                    <img src={starIcon} />
                  </a>
                </li>
                <li>
                  <a href="#.">
                    <img src={starIcon} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="titleWrapp" id="pricing_section">
        <div className="PricingTitle">
          <h2>METAGEO Pricing</h2>
        </div>
        <div className="PricingTitle">
          <p>
            Only pay for what you use. Don’t get stalled by contracts, capacity
            planning, or price modeling.
          </p>
        </div>
      </div>
      <div className="containerPricing">
        <div className="row">
          <div className="col">
            <div className="PriceBox">
              <div className="PlanHeader">
                <h2>Plan 1</h2>
              </div>
              <div className="PlanPricing">
                <h2>
                  $10/<span>month</span>
                </h2>
              </div>
              <div className="PricingData">
                <p>
                  2x 
                </p>
                <p>
                  Free 
                </p>
                <p>
                  Unlimited 
                </p>
                <p>
                  Unlimited 
                </p>
                <p>
                  1x
                </p>
              </div>
              <div className="PricingFooter">
                <Link to={"/signup"} className="nav-link">
                  PURCHASE
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="PriceBox Special">
              <div className="PlanHeader">
                <h2>Plan 2</h2>
              </div>
              <div className="PlanPricing">
                <h2>
                  $10/<span>month</span>
                </h2>
              </div>
              <div className="PricingData">
                <p>
                  2x 
                </p>
                <p>
                  Free 
                </p>
                <p>
                  Unlimited 
                </p>
                <p>
                  Unlimited 
                </p>
                <p>
                  1x
                </p>
              </div>
              <div className="PricingFooter">
                <Link to={"/signup"} className="nav-link">
                  PURCHASE
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="PriceBox">
              <div className="PlanHeader">
                <h2>Plan 3</h2>
              </div>
              <div className="PlanPricing">
                <h2>
                  $30/<span>month</span>
                </h2>
              </div>
              <div className="PricingData">
                <p>
                  2x 
                </p>
                <p>
                  Free 
                </p>
                <p>
                  Unlimited 
                </p>
                <p>
                  Unlimited 
                </p>
                <p>
                  1x
                </p>
              </div>
              <div className="PricingFooter">
                <Link to={"/signup"} className="nav-link">
                  PURCHASE
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="PriceBox">
              <div className="PlanHeader">
                <h2>Plan 4</h2>
              </div>
              <div className="PlanPricing">
                <h2>
                  $40/<span>month</span>
                </h2>
              </div>
              <div className="PricingData">
                <p>
                  2x 
                </p>
                <p>
                  Free 
                </p>
                <p>
                  Unlimited 
                </p>
                <p>
                  Unlimited 
                </p>
                <p>
                  1x
                </p>
              </div>
              <div className="PricingFooter">
                <Link to={"/signup"} className="nav-link">
                  PURCHASE
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="clearFix"></div>
      </div>

      <div className="PartnerTitle">
        <h2>Our Partners</h2>
      </div>

      <div className="containerPartner">
        <div className="PartnerRow">
          <img src={P1} />
          <img src={P2} />
          <img src={P3} />
          <img src={P4} />
          <img src={P5} />
          <img src={P6} />
        </div>
        <div className="PartnerRow overwrite">
          <img src={P7} />
          <img src={P8} />
          <img src={P9} />
          <img src={P10} />
          <img src={P11} />
        </div>
        <div className="PartnerRow overwrite">
          <img src={P11} />
          <img src={P12} />
          <img src={P13} />
          <img src={P14} />
          <img src={P15} />
          <img src={P16} />
        </div>
      </div>

      {/* <div className="ContactForm">
                <div className="formWrapp">
                    <form method="POST">
                        <div className="form-group">
                            <div className="FieldSide">
                                <input type="text" className="form-control"></input>
                            </div>
                            <div className="LabelSide"><label className="control-label">Name</label></div>
                        </div>
                        <div className="form-group">
                            <div className="FieldSide">
                                <input type="text" className="form-control"></input>
                            </div>
                            <div className="LabelSide"><label className="control-label">E-mail</label></div>
                        </div>
                        <div className="form-group">
                            <div className="FieldSide">
                                <input type="text" className="form-control"></input>
                            </div>
                            <div className="LabelSide"><label className="control-label">Web site</label></div>
                        </div>
                        <div className="form-group">
                            <textarea className="form-control"></textarea>
                        </div>
                        <input type="Submit" className="buttonSubmit" value="Send"></input>
                    </form>
                </div>
            </div> */}
    </div>
  );
};

export default VideoHome;
