import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { blue } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import secureLocalStorage from "react-secure-storage";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import PersonIcon from "@material-ui/icons/Person";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getOrganizationProjects,
  getProjectsUsers,
  updateProjectPermissions,
} from "../../services/user.service";
import { LOADING_MESSAGE, SET_MESSAGE } from "./../../actions/types";
import { allowedUser } from "./../common/common";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function validateField(val) {
  return val != "";
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const OrganizationProjects = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOrganizationAdmin } = useSelector((state) => state.auth);
  const { message, isLoading } = useSelector(
    (state) => state.message,
    shallowEqual
  );
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [entriesLength, setEntriesLength] = useState(true);
  const [users, setUsers] = useState([]);
  const [currentProject, setCurrentProject] = useState("");
  const [edittedUsers, setEdittedUsers] = useState([]);
  const [data, setData] = useState([]);
  const typeID = JSON.parse(secureLocalStorage.getItem("typeID"));

  const columns = [
    {
      title: "Project Name",
      field: "name",

      validate: (rowData) => {
        if (validateField(rowData.header)) {
        }

        return validateField(rowData.header) && rowData.header
          ? ""
          : "Tip Header is required";
      },
    },
    { title: "Project ID", field: "id", editable: "never" },

    // {
    //   title: "Tip Detail",
    //   field: "details",

    //   validate: (rowData) => {
    //     if (validateField(rowData.details)) {
    //     }

    //     return validateField(rowData.details) && rowData.details
    //       ? ""
    //       : "Tip Detail is required";
    //   },
    // },
  ];

  const handleCloseDeleteDialog = () => {
    setEntriesLength(true);
    setDeleteDrawer(false);
  };

  const errorDisplay = (type, message) => {
    dispatch({
      type: SET_MESSAGE,
      payload: { message: message, severity: type, isOpen: true },
    });
  };

  useEffect(() => {
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    getOrganizationProjects(
      JSON.parse(secureLocalStorage.getItem("organizationID"))
    )
      .then((res) => {
        setData(res.data.data);
        dispatch({ type: LOADING_MESSAGE, isLoading: false });
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            message: error.response
              ? error.response.data.message
              : "Team fetch error",
            severity: "error",
            isOpen: true,
          },
        });
      });
    sessionStorage.setItem("reloadMap", 1);
  }, []);

  return (
    <div className="margindiv usersTable">
      {secureLocalStorage.getItem("isOrganizationAdmin") ||
      allowedUser.includes(typeID) ? (
        <MaterialTable
          options={{
            exportButton: true,
            exportAllData: true,
          }}
          icons={tableIcons}
          title="Shared Team Projects"
          columns={columns}
          data={data}
          actions={[
            {
              icon: Edit,
              // disabled:rowData.isVerified?true:false,
              tooltip: "Edit Permissions",
              onClick: (event, rowData) => {
                dispatch({ type: LOADING_MESSAGE, isLoading: true });
                getProjectsUsers(
                  JSON.parse(secureLocalStorage.getItem("organizationID")),
                  rowData.id
                ).then((res) => {
                  setCurrentProject(rowData.id);
                  setUsers(res.data.data.projectUsers);
                  dispatch({ type: LOADING_MESSAGE, isLoading: false });
                  setEntriesLength(true);
                  setDeleteDrawer(true);
                  setEdittedUsers([]);
                });
              },
            },
          ]}
        />
      ) : (
        <h2>You must have an Enterprise Account to create a Team.</h2>
      )}
      <Backdrop open={isLoading} style={{ zIndex: 99999999999, color: "#fff" }}>
        <CircularProgress />
      </Backdrop>
      <Dialog
        open={deleteDrawer}
        TransitionComponent={Transition}
        onClose={handleCloseDeleteDialog}
        className="projectUsers"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Project Permissions"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent> */}
        {/* <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle> */}
        <List style={{ height: 320, overflow: "auto" }}>
          {users.map((user) => {
            return (
              <ListItem
                button
                //   onClick={() => handleListItemClick(user.email)}
                key={user.email}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  style={{ marginRight: 10 }}
                  primary={user.email}
                />
                <Autocomplete
                  disabled={secureLocalStorage.getItem("email") == user.email}
                  onChange={(a, b, c) => {
                    // let entries= edittedUsers;
                    const index = edittedUsers.findIndex(
                      (e) => e.userID === user.id
                    );
                    if (index === -1) {
                      edittedUsers.push({
                        view: b.includes("View") || b.includes("All"),
                        edit: b.includes("Edit") || b.includes("All"),
                        delete: b.includes("Delete") || b.includes("All"),
                        download: b.includes("Download") || b.includes("All"),
                        userID: user.id,
                      });
                    } else {
                      edittedUsers[index] = {
                        view: b.includes("View") || b.includes("All"),
                        edit: b.includes("Edit") || b.includes("All"),
                        delete: b.includes("Delete") || b.includes("All"),
                        download: b.includes("Download") || b.includes("All"),
                        userID: user.id,
                      };
                    }
                    if (edittedUsers.length) {
                      setEntriesLength(false);
                    } else {
                      setEntriesLength(true);
                    }
                    // if (a.target.innerHTML.includes("All")) {
                    //   let temp = users.map((item) => {
                    //     if (item.id == user.id) {
                    //       console.log("TempConsole", item, user);
                    //       item.projectPermissions[0].download = true;
                    //       item.projectPermissions[0].delete = true;
                    //       item.projectPermissions[0].edit = true;
                    //       item.projectPermissions[0].view = true;
                    //     }
                    //     return item;
                    //   });
                    //   setUsers(users.filter((item) => item.id != user.id));
                    //   setTimeout(() => {
                    //     setUsers(temp);
                    //   }, 1);
                    // }
                    if (a.target.innerHTML.includes("All")) {
                      let temp = users.map((item) => {
                        if (item.id === user.id) {
                          return {
                            ...item,
                            projectPermissions: [
                              {
                                ...item.projectPermissions[0],
                                download: true,
                                delete: true,
                                edit: true,
                                view: true,
                              },
                            ],
                          };
                        }
                        return item;
                      });

                      setUsers(users.filter((item) => item.id != user.id));
                      setTimeout(() => {
                        setUsers(temp);
                      }, 1);
                    }
                    // currentProject
                    // edittedUsers
                    // ;
                    // setEdittedUsers(entries)

                    // if (b.length < 6) {
                    //   // setInfoLayerTags(b);
                    // } else {
                    //   // setMessageAlertType("error");
                    //   // setMessageAlert("Maximum 5 Tags can be added");
                    //   // handleClickAlert();
                    // }
                  }}
                  size="small"
                  style={{ width: 350 }}
                  multiple
                  id="tags-outlined"
                  options={["All", "View", "Edit", "Delete", "Download"]}
                  getOptionLabel={(option) => option}
                  defaultValue={() => {
                    if (user.projectPermissions.length) {
                      let selections = [];
                      if (user.projectPermissions[0].view) {
                        selections.push("View");
                      }
                      if (user.projectPermissions[0].download) {
                        selections.push("Download");
                      }
                      if (user.projectPermissions[0].delete) {
                        selections.push("Delete");
                      }
                      if (user.projectPermissions[0].edit) {
                        selections.push("Edit");
                      }
                      return selections;
                    } else {
                      return [];
                    }
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Permissions"
                      // placeholder="Permissions"
                    />
                  )}
                />
              </ListItem>
            );
          })}

          {/* <ListItem
            autoFocus
            button
            // onClick={() => handleListItemClick('addAccount')}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Add account" />
          </ListItem> */}
        </List>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              dispatch({ type: LOADING_MESSAGE, isLoading: true });
              updateProjectPermissions(
                JSON.parse(secureLocalStorage.getItem("organizationID")),
                currentProject,
                edittedUsers
              )
                .then(() => {
                  dispatch({ type: LOADING_MESSAGE, isLoading: false });
                  handleCloseDeleteDialog();
                })
                .catch((error) => {
                  dispatch({ type: LOADING_MESSAGE, isLoading: false });
                  errorDisplay(
                    "error",
                    error.response
                      ? error.response.data.message
                      : "Request Unsuccessful"
                  );
                });
            }}
            color="primary"
            disabled={entriesLength}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default OrganizationProjects;
