import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPolicy, updatePolicy } from "../../services/user.service";
import { displayMessage } from "./../common/common";

const Policy = () => {
  const dispatch = useDispatch();
  const [policyText, setPolicyText] = useState("");
  const [policy, setPolicy] = useState("");
  const [backDropOpen, setBackDropOpen] = React.useState(true);

  useEffect(() => {
    getPolicy().then((data) => {
      setPolicyText(data.data.eulaPolicy.policy.value);
      setPolicy(data.data.eulaPolicy.id);
      setBackDropOpen(false);
    });
  }, []);

  const handlePolicy = (e) => {
    e.preventDefault();

    if (policyText != "") {
      setBackDropOpen(true);
      updatePolicy(policyText, policy)
        .then(() => {
          dispatch(displayMessage("success", "Policy updated successfully!"));
          setBackDropOpen(false);
        })
        .catch(() => {
          setSuccessful(false);
        });
    } else {
      dispatch(displayMessage("error", "Please fill the required field"));
    }

  };

  return (
    <>
      <div className="MainLoginBg"></div>
      <div className="SigninWrapp SigninWrappPolicy">
        <div className="SigninTitle">
          <div className="inlineBox">
            <h2>Update EULA</h2>
          </div>
          <div className="inlineBox text-right"></div>
        </div>

        <TabPanel value={0} index={0}>
          <div style={{ marginBottom: 15 }}>
            <TextField
              required
              fullWidth
              onChange={(a, b, c) => {
                setPolicyText(a.target.value);
              }}
              id="outlined-multiline-static"
              label="EULA"
              multiline
              rows={15}
              value={policyText}
              variant="outlined"
            />
          </div>

          <div>
            <Button
              type="submit"
              onClick={handlePolicy}
              variant="contained"
              className="btn-block btnTheme"
            >
              Save
            </Button>
          </div>
        </TabPanel>
      </div>
      <div className="Overlay"></div>
      <Backdrop
        open={backDropOpen}
        style={{ zIndex: 99999999999, color: "#fff" }}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}

export default Policy;
