import React, { useState, useRef, useEffect } from "react";
import { getPolicy, updatePolicy } from "../../services/user.service";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
const PrivacyPolicy = () => {
  const [email, setEmail] = useState("");
  const [backDropOpen, setBackDropOpen] = React.useState(true);
  useEffect(() => {
    getPolicy().then((data) => {
      setEmail(data.data.eulaPolicy.policy.value);
      setBackDropOpen(false);
    });
  }, []);
  return (
    <>
      <div className="margindiv" style={{ paddingBottom: 60 }}>
        <h1>Master Services Agreement</h1>
        <textarea className="eulaDiv" value={email}> </textarea>
      </div>
      <Backdrop
        open={backDropOpen}
        style={{ zIndex: 99999999999, color: "#fff" }}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default PrivacyPolicy;
