import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { LOADING_MESSAGE, SET_MESSAGE } from "../../actions/types";
import AuthService from "../../services/auth.service";
import SigninImage from "../images/avatar.png";
import  secureLocalStorage  from  "react-secure-storage";

let repPass = "";

const ResetPassword = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { isLoading } = useSelector((state) => state.message, shallowEqual);

  const [key, setKey] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  useEffect(() => {
    const resetKey = secureLocalStorage.getItem("reset-key");
    if (resetKey) {
      setKey(resetKey);
    } else {
      dispatch({
        type: SET_MESSAGE,
        payload: { message: "Token Missing", severity: "error", isOpen: true },
      });
    }
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== repPass) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("isTruthy", (value) => value);
  }, []);

  const onChangePassword = (e) => {
    repPass = e.target.value;
    setPassword(e.target.value);
  };

  const onChangeRepeatPassword = (e) => {
    setRepeatPassword(e.target.value);
  };

  const resetPassword = (e) => {
    e.preventDefault();
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    AuthService.resetPassword(key, password)
      .then((res) => {
        secureLocalStorage.setItem("reset-key", null);
        history.push("/login");
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message || error?.message || error?.toString();
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, severity: "error", isOpen: true },
        });
      });
  };

  return (
    <>
      <div className="MainLoginBg"></div>
      <div className="SigninWrapp">
        <div className="SigninTitle">
          <div className="inlineBox">
            <h2>Reset Password</h2>
          </div>
          <div className="inlineBox text-right">
            <img src={SigninImage} alt="" />
          </div>
        </div>
        <TabPanel value={0} index={0}>
          <ValidatorForm className="FormStyle" onSubmit={resetPassword}>
            <div>
              <label>Password</label>
              <TextValidator
                placeholder="Password"
                fullWidth
                onChange={onChangePassword}
                name="password"
                autoComplete="new-password"
                type="password"
                validators={[
                  "required",
                  "matchRegexp:^(?=.*[0-9])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,}$",
                ]}
                errorMessages={[
                  "this field is required",
                  "Password should be minimum eight characters, at least one letter and one number",
                ]}
                value={password}
              />
            </div>
            <div>
              <label>Confirm Password</label>
              <TextValidator
                placeholder="Confirm Password"
                fullWidth
                onChange={onChangeRepeatPassword}
                name="repeatPassword"
                autoComplete="new-password"
                type="password"
                validators={["isPasswordMatch", "required"]}
                errorMessages={["password mismatch", "this field is required"]}
                value={repeatPassword}
              />
            </div>
            <div>
              <Button
                type="submit"
                variant="contained"
                className="btn-block btnTheme"
                disabled={isLoading}
              >
                Save
              </Button>
            </div>
          </ValidatorForm>
        </TabPanel>
        <div className="text-center terms">
          <p>
            By continuing, you agree to metageo{" "}
            <Link target={"_blank"} to="/terms-of-service">
              Terms of Service
            </Link>{" "}
            and{" "}
            <a target={"_blank"} href="https://www.metageo.io/privacy-policy">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
      <div className="Overlay"></div>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}

export default ResetPassword;
