import React from "react";

const ShareLoading = () => {
  return (
    <div style={{ height: "100vh", display: "grid" }}>
      <img
        src="https://metageo-assets.s3.us-west-1.amazonaws.com/MetaLoader.gif"
        width="65"
        style={{ margin: "auto", marginBottom: "15px" }}
      />
      <h2
        style={{
          position: "relative",
          marginInline: "auto",
        }}
      >
        Validating Link...
      </h2>
    </div>
  );
};

export default ShareLoading;
